<template>
    <div class="index-content">
        <div class="standard">
            <p class="standard-title">考级标准</p>
            <div class="standard-list">
                <div class="standard-item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                    <img :src="item.image" class="standard-item-img"/>
                    <div class="standard-item-info">
                        <p class="standard-item-info-title">{{ item.title }}</p>
                        <div class="standard-item-info-bottom">
                            <span>{{ item.releasetimes }}</span>
                            <div>了解更多 》</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 分页 -->
            <paging @changeIndex="changeIndex" :allNum="total"></paging>
        </div>
    </div>
</template>
<script>
import paging from '@/components/paging'
export default {
    name: 'standard',
    components:{
        paging
    },
    data(){
        return{
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            this.$request({
                url:'/api/article/exam',
                method:'POST',
                data:{
                    page:this.page,
                    limit:this.limit
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'考级标准')
                    this.list=res.data.list
                    this.count=res.data.count
                    this.total=res.data.total
                }
            })
        },
        navto(item) {
            this.$router.push({
                path:"/finArt/finArtDetail",
                query:{
                    id:item.id
                }
            })
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index-content{
        background: #F6F6EA;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .standard{
        width: 1200px;
        background: #ffffff;
        padding: 40px;
    }
    .standard-title{
        font-weight: 600;
        color: #333333;
        font-size: 30px;
        padding-bottom: 40px;
        text-align: center;
    }
    .standard-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .standard-item{
        width: 32%;
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        cursor: pointer;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
    }
    .standard-item:hover{
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.12);
        transform: translateY(-10px);
        transition: all 1s;
    }
    .standard-item-img{
        width: 100%;
        height: 242px;
    }
    .standard-item-info{
        padding: 16px 25px;
        display: flex;
        flex-direction: column;
        
    }
    .standard-item-info-title{
        color: #313131;
        font-size: 18px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .standard-item-info-bottom{
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #656565;
        font-size: 14px;
    }
</style>